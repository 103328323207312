import './App.css'
import Main from './components/Main/Main'
import Login from './components/Login/Login'
import ForgotPassword from './components/Login/ForgotPassword'
import LoginLoad from './components/LoginLoad/LoginLoad'
import NotFound404 from './components/ErrorPages/404NotFound/NotFound'

import { Route , Routes, Navigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { userActions } from './store/user-slice'

// import RecoverPassword from './components/RecoverPassword/RecoverPassword';
// import ResetPassword from './components/ResetPassword/ResetPassword';
// import { useNavigate } from "react-router-dom";
// import { userActions } from './store/user-slice';
// import { TimeOutHandler } from './utils/helper_functions';

import { uiActions } from './store/ui-slice'
import Register from './components/Register/Register'
import "rsuite/dist/rsuite.css"


function App() {
  // const navigate = useNavigate ()
  // const initPath = useRef(window.location.pathname)
  // const navigateToCustomers = useSelector(state => state.ui.navigateToCustomers)
  // const isLogged = useSelector(state => state.ui.isLoggedIn)
  
  // const API_URL = process.env.REACT_APP_API_URL
  // const PATH_NAME = window.location.pathname
  


  const dispatch = useDispatch()
  // useselector section
  const isLoggedIn = useSelector(state => state.ui.isLoggedIn)


  // states
  const [loginProcess, setLoginProcess] = useState(false)

  useEffect(()=>{
    let name = localStorage.getItem('name') || ''
    dispatch(userActions.setUserName(name))

    let balance = Number(localStorage.getItem('balance')) || 0.00
    dispatch(userActions.setUserBalance(balance))

    let last_payment_amount = Number(localStorage.getItem('last_payment_amount')) || 0.00
    dispatch(userActions.setLastPaymentAmount(last_payment_amount))

    // let is_admin_user = localStorage.getItem('isAdmin') || false
    // dispatch(userActions.setUserBalance(balance))

    let token = localStorage.getItem('access_token')
    if(isLoggedIn && !token){
      dispatch(uiActions.setIsLoggedIn(false))
    }
  })


  // functions
  // useEffect(() => {
  //   let token = localStorage.getItem('access_token')
  //   let role = localStorage.getItem('role')
  //   if(token && role) {
  //     setLoginProcess(true)
  //     // LoginHandler(token, role).catch(ex=>{
  //     //   console.log("Error - ", ex)
  //     //   dispatch(uiActions.setIsLoggedIn(false))
  //     //   setLoginProcess(false)
  //     // })
  //   }else{
  //     dispatch(uiActions.setIsLoggedIn(false))
  //   }
  // },[isLoggedIn])


  // const LoginHandler = (token,role) => {
  //   let REQ_PATH = ['ADMIN','MODERATOR','OBSERVER'].includes(role) ? `${API_URL}/api/v2/employees/main` : `${API_URL}/api/v2/customers/main`

  //   return fetch(REQ_PATH, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'authorization': `Bearer ${token}`}
  //   })
  //   .then(val => {
  //     // Case user edited the values in the localStorage
  //     if(val.status !== 200) {
  //       localStorage.removeItem('access_token')
  //       localStorage.removeItem('role')
  //       navigate('/login',{ replace: true })
  //       setLoginProcess(false)
  //     }
  //     // Case all values are correct
  //     else {
  //       return val.json()
  //     }
  //   })
  //   .then(data => {

  //     dispatch(userActions.setUser(data.result))
  //     dispatch(userActions.setIsAdmin(['ADMIN'].includes(role)))
  //     dispatch(userActions.setIsModerator(['ADMIN','MODERATOR'].includes(role)))
  //     dispatch(userActions.setToken(token))
  //     dispatch(uiActions.setIsLoggedIn(true))
  //     dispatch(uiActions.setShowAdminInterface(['ADMIN','MODERATOR'].includes(role)))

  //     if(PATH_NAME === '/reset') {navigate('/reset', { replace: true })}

  //     else {
  //       if(navigateToCustomers) {
  //         navigate('/user/customers',{ replace: true })
  //         TimeOutHandler(() => {setLoginProcess(false)})
  //       }
  //       else {

  //         if(!initPath.current.includes('login')) {
  //           navigate(initPath.current,{ replace: true })
  //           TimeOutHandler(() => {setLoginProcess(false)})
  //         }
  //         else {
  //           navigate('/',{ replace: true })
  //           TimeOutHandler(() => {setLoginProcess(false)})
  //         }
  //       }
  //     }
  //   })
  // }


  // if(!isLoggedIn){
  //   console.log("not logged in")
  //   if(window.location.pathname==="/"){
  //     window.location.href = 'https://start.linkvantage.net/';
  //     return "";
  //   }
  // }

  const RedirectToStart = (props) => {

    if(props.isLoggedIn){
      window.location.href = "/dashboard"
    }else{
      window.location.href = "https://start.linkvantage.net/"
    }

    return <>
    </>
      
    
  }



  return (
    <div className="app">
      {loginProcess && <LoginLoad />}
      <Routes>
        <Route path='/login' exact element={<Login setLoginProcess={setLoginProcess}/>}/>
        <Route path='/reset-password' exact element={<ForgotPassword reset={true} setLoginProcess={setLoginProcess}/>}/>
        <Route path='/forgot-password' exact element={<ForgotPassword setLoginProcess={setLoginProcess}/>}/>
        <Route path='/register' exact element={<Register setLoginProcess={setLoginProcess}/>}/>
        <Route path='/admin/login/' exact element={<Login setLoginProcess={setLoginProcess}/>}/>
    
        {/* <Route path='/reset' element={<ResetPassword />}/> */}
        {/* <Route path ='/recover-password' exact element={<RecoverPassword />} /> */}
        
        <Route path='/' exact element={!isLoggedIn ? <RedirectToStart isLoggedIn={isLoggedIn}/> : <RedirectToStart isLoggedIn={isLoggedIn} />}/>        
        {/* <Route path='/' exact element={!isLoggedIn ? <Navigate to='/dashboard'/> : <RedirectToStart/>}/>         */}
        <Route path='/admin' exact element={isLoggedIn ? <Navigate to='/admin/dashboard'/> : <Navigate to={'/login'}/>}/>        
        <Route path='/:page' element={isLoggedIn ? <Main /> : <Navigate to='/login'/>}/>
        <Route path='/admin/:page' exact element={isLoggedIn ? <Main /> : <Navigate to='/404-not-found'/>}/>
        <Route path='/:page/:offerId' exact element={isLoggedIn ? <Main /> : <Navigate to='/404-not-found'/>}/>
        
        <Route path='/404-not-found' exact element={<NotFound404 />}/>
        <Route path='/:any' exact element={isLoggedIn ? <Navigate to='/dashboard'/> : <Navigate to='/404-not-found'/>}/>

      </Routes>
    </div>
  )
}

export default App;
