import classes from './NavBar.module.scss'
import navlink_classes from '../NavBarItem/NavBarItem.module.scss'


import NavBarItem from '../NavBarItem/NavBarItem'
import home_white from '../../assets/icons/dashboard_white.svg'
import tagsSolid from '../../assets/icons/tags-solid.svg'
import userSolid from '../../assets/icons/user-solid.svg'
import usersSolid from '../../assets/icons/users-new-solid.svg'
import helpIcon from '../../assets/icons/circle-question-regular.svg'
import logoutIcon from '../../assets/icons/right-from-bracket-solid.svg'

import offer_white from '../../assets/icons/flame_white.svg'
import tools_white from '../../assets/icons/tools_white.svg'
import users_white from '../../assets/icons/admin_white.svg'
import conversions_white from '../../assets/icons/clicks_white.svg'
import approval_white from '../../assets/icons/shield_check_white.svg'

import admin_payment from '../../assets/icons/credit_card_white.svg'

import stat_white from '../../assets/icons/stats_white.svg'
import tracking from '../../assets/icons/tracking.svg'
import money_white from '../../assets/icons/money-white.svg'
import cross_white from '../../assets/icons/cross_white.svg'

import arrow_up from '../../assets/icons/arrow_up_white.svg'
import arrow_down from '../../assets/icons/arrow_down_white.svg'


import { useSelector, useDispatch } from 'react-redux'
import { uiActions } from '../../store/ui-slice'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import foreshopSmallT from '../../assets/images/logo-transparent.png'
import { NavLink } from 'react-router-dom'

import useWindowSize from '../../assets/hooks/useWindowSize.js'


const NavBar = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const NAMES = useSelector(state => state.ui.language.values)
    const awaitingOffersNumber = useSelector(state => state.ui.awaitingOffersNumber)
    const fetchAwaitingOffersNumber = useSelector(state => state.ui.fetchAwaitingOffersNumber)

    const sideBarNames = NAMES.sideBarNames
    let {width} = useWindowSize()
    // let minimizeNavBar = useSelector(state => state.ui.minimizeNavBar)    

    // states
    const [isAdmin, setIsAdmin] = useState(false)
    const [showAdminSetting, setShowAdminSetting] = useState(false)

    useEffect(() => {
        let tmp = localStorage.getItem('role')
        let isAdminData = (tmp==='ADMIN')
        setIsAdmin(isAdminData)
    }, [])


    useEffect(()=>{
        if(width <= 767){
            props.changeSideBar(false)
        }
    }, [width])

    const logoutHandler = () => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('role')
        dispatch(uiActions.setIsLoggedIn(false))
        navigate('/login/',{ replace: true })
    }

    
    const handleCrossClicked = () => {
        props.changeSideBar(!props.showSideBar)
    }


    return (
        <>
            {width <= 767 ? (
                <>
                {props.showSideBar &&
                <div className={classes.navbar_container}>
                    <div className={classes.navbar_container} style={{display:"contents"}}>
                        <div className={classes.navbar_container_top_icon_div}>
                            <div className={classes.logo}>
                                <div><img src={foreshopSmallT} alt='ForeMedia'/></div>
                                <div className={classes.logo_text}>Link Vantage</div>
                            </div>
                            <img className={classes.close_icon} onClick={handleCrossClicked} src={cross_white} alt="X"/>
                        </div>
                    </div>

                    <div className={classes.navbar_container_top_bottom_container}>
                        <div className={classes.navbar_container_top}>
                            <NavBarItem changeSideBar={handleCrossClicked} showSideBar={props.showSideBar} image_src={home_white} image_alt={sideBarNames.dashboard} name={sideBarNames.dashboard} route='dashboard'/>
                            <NavBarItem changeSideBar={handleCrossClicked} showSideBar={props.showSideBar} image_src={tagsSolid} image_alt={sideBarNames.offers} name={sideBarNames.offers} route='offers'/>
                            <NavBarItem changeSideBar={handleCrossClicked} showSideBar={props.showSideBar} image_src={stat_white} image_alt={sideBarNames.reports} name={sideBarNames.reports} route='reports'/>
                            <NavBarItem changeSideBar={handleCrossClicked} showSideBar={props.showSideBar} image_src={tracking} image_alt={"Tracking"} name={"Tracking"} route='tracking'/>
                            <NavBarItem changeSideBar={handleCrossClicked} showSideBar={props.showSideBar} image_src={userSolid} image_alt={"Profile"} name={"Profile"} route='profile'/>
                            <NavBarItem changeSideBar={handleCrossClicked} showSideBar={props.showSideBar} image_src={money_white} image_alt={sideBarNames.reports} name={"Billing"} route='billing'/>
                            
                            {isAdmin &&
                                <div className={navlink_classes.container} onClick={()=> setShowAdminSetting(!showAdminSetting)}>
                                    <div className={navlink_classes.image}><img src={showAdminSetting?arrow_up:arrow_down} alt="Admin Settings"/></div>
                                    <div className={navlink_classes.name}>Admin Settings</div>
                                </div>
                            }

                            {showAdminSetting && (
                                <>
                                    {isAdmin && <NavBarItem image_src={users_white} image_alt={"Admin Users"} name={"Admin Users"} route='admin-users'/>}
                                    {isAdmin && <NavBarItem image_src={offer_white} image_alt={"Admin Offers"} name={"Admin Offers"} route='admin-offers'/>}
                                    {isAdmin && <NavBarItem image_src={admin_payment} image_alt={"Admin Billing"} name={"Admin Billing"} route='admin-billing'/>}
                                    {isAdmin && <NavBarItem image_src={tools_white} image_alt={"Admin Tools"} name={"Admin Tools"} route='admin-tools'/>}
                                    {isAdmin && <NavBarItem image_src={approval_white} image_alt={"Approval"} name={"Approval"} route='admin-approval'/>}
                                    {isAdmin && <NavBarItem image_src={conversions_white} image_alt={"Conversions"} name={"Conversions"} route='admin-conversions'/>}
                                </>
                            )}
                        
                        </div>
                        <div><hr/></div>
                        <div className={classes.navbar_container_bottom}>
                            <NavLink target='_blank' to={"https://chat.linkvantage.net/"} className={classes.external_link}> 
                                <div className={classes.external_link_image}><img src={usersSolid} alt={sideBarNames.offers}/></div>
                                <div className={classes.external_link_name}>Community</div>
                            </NavLink>
                            
                            <NavLink to={"#"} className={classes.external_link}> 
                                <div className={classes.external_link_image}><img src={helpIcon} alt={sideBarNames.offers}/></div>
                                <div className={classes.external_link_name}>Help</div>
                            </NavLink>
                            
                            <NavLink to={"#"}  className={classes.external_link} onClick={logoutHandler} style={{marginBottom:"4em"}}> 
                                <div className={classes.external_link_image}>
                                    <img src={logoutIcon} alt={sideBarNames.offers}/>
                                </div>
                                <div className={classes.external_link_name}>Logout</div>
                            </NavLink>

                            {/* <NavBarItem image_src={usersSolid} image_alt={} name={sideBarNames.offers} route='https://chat.linkvantage.net/'/> */}
                            {/* <NavBarItem image_src={community_white} image_alt={sideBarNames.community} name={sideBarNames.community} route='community'/> */}
                            {/* <NavBarItem image_src={profile_white} image_alt={sideBarNames.profile} name={sideBarNames.profile} route='profile'/> */}
                        
                            {/* <div className={classes.navbar_group_btns}> */}
                                {/* <a href="/profile">
                                <div className={classes.logout}>
                                        <IconContainerMedium color='white' image_src={profile_white} alt='profile'/>
                                    </div> 
                                </a> */}
                                {/* <div className={classes.language_container} onClick={toggleLanguageHandler}>
                                    <IconContainerMedium color='white' image_src={translate} alt='translate'/>
                                </div> */}
                                {/* <div className={classes.logout} onClick={logoutHandler}>
                                    <IconContainerMedium color='white' image_src={sign_out_white} alt='logout'/>
                                </div>  */}
                            {/* </div> */}

                            <span className={classes.navbar_footer_text} style={{display:"contents"}}>
                                Powered by
                                <a  style={{color: "#fff", fontWeight: "bold"}} rel="noreferrer" href="https://abracadabra.net/" target='_blank'>
                                    <img src="/abracadabra.png" style={{width:"1.5em", marginRight:"0.5em"}} alt="abracadabra.net"/>
                                    Abracadabra.net
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
                }
                </>





            // tablet and desktop
        ):(
            <div className={classes.container}> 
                <div className={classes.navbar_container} style={{display:"contents"}}>
                    <div className={classes.navbar_container_top} style={{marginTop: "0", borderBottom: "0.1px solid #fff", marginLeft: 0, marginRight: 0, width: "100%"}}>
                        <div className={classes.logo}>
                            <img src={foreshopSmallT} alt='ForeMedia'/> 
                            <div className={classes.logo_text}><b>Link</b>Vantage</div>
                        </div>
                    </div>
                </div>
                <div className={classes.navbar_container}>
                    <div className={classes.navbar_container_top}>
                        <NavBarItem image_src={home_white} image_alt={sideBarNames.dashboard} name={sideBarNames.dashboard} route='dashboard'/>
                        <NavBarItem image_src={tagsSolid} image_alt={sideBarNames.offers} name={sideBarNames.offers} route='offers'/>
                        <NavBarItem image_src={stat_white} image_alt={sideBarNames.reports} name={sideBarNames.reports} route='reports'/>
                        <NavBarItem image_src={tracking} image_alt={"Tracking"} name={"Tracking"} route='tracking'/>
                        <NavBarItem image_src={userSolid} image_alt={"Profile"} name={"Profile"} route='profile'/>
                        <NavBarItem image_src={money_white} image_alt={sideBarNames.reports} name={"Billing"} route='billing'/>

                        {isAdmin &&
                            <div className={navlink_classes.container} onClick={()=> setShowAdminSetting(!showAdminSetting)}>
                                <div className={navlink_classes.image}><img src={showAdminSetting?arrow_up:arrow_down} alt="Admin Settings"/></div>
                                <div className={navlink_classes.name}>Admin Settings</div>
                            </div>
                        }
                        {showAdminSetting && (
                            <>
                                {isAdmin && <NavBarItem image_src={users_white} image_alt={"Admin Users"} name={"Admin Users"} route='admin-users'/>}
                                {isAdmin && <NavBarItem image_src={offer_white} image_alt={"Admin Offers"} name={"Admin Offers"} route='admin-offers'/>}
                                {isAdmin && <NavBarItem image_src={admin_payment} image_alt={"Admin Billing"} name={"Admin Billing"} route='admin-billing'/>}
                                {isAdmin && <NavBarItem image_src={tools_white} image_alt={"Admin Tools"} name={"Admin Tools"} route='admin-tools'/>}
                                {isAdmin && <NavBarItem image_src={approval_white} image_alt={"Approval"} name={"Approval"} route='admin-approval'/>}
                                {isAdmin && <NavBarItem image_src={conversions_white} image_alt={"Conversions"} name={"Conversions"} route='admin-conversions'/>}
                            </>
                        )}
                        
                        {/* <NavBarItem image_src={tools_white} image_alt={sideBarNames.tools} name={sideBarNames.tools} route='tools'/>
                        <NavBarItem image_src={payment_white} image_alt={sideBarNames.billings} name={sideBarNames.billings} route='billing-payments'/>
                        <NavBarItem image_src={book_white} image_alt={sideBarNames.logs} name={sideBarNames.logs} route='logs'/> */}

                    </div>

                    <div className={classes.navbar_container_bottom}>
                        {/* <NavLink target='_blank' to={"https://chat.linkvantage.net/"} className={classes.external_link}> 
                            <div className={classes.external_link_image}><img src={usersSolid} alt={sideBarNames.offers}/></div>
                            <div className={classes.external_link_name}>Community</div>
                        </NavLink> */}
                        
                        <NavLink to={"mailto:support@linkvantage.net"} className={classes.external_link}> 
                            <div className={classes.external_link_image}><img src={helpIcon} alt={sideBarNames.offers}/></div>
                            <div className={classes.external_link_name}>Help</div>
                        </NavLink>
                        
                        <NavLink to={"#"}  className={classes.external_link} style={{marginBottom:"4em"}} onClick={logoutHandler}> 
                            <div className={classes.external_link_image}>
                                <img src={logoutIcon} alt={sideBarNames.offers}/>
                            </div>
                            <div className={classes.external_link_name}>Logout</div>
                        </NavLink>

                        {/* <NavBarItem image_src={usersSolid} image_alt={} name={sideBarNames.offers} route='https://chat.linkvantage.net/'/> */}
                        {/* <NavBarItem image_src={community_white} image_alt={sideBarNames.community} name={sideBarNames.community} route='community'/> */}
                        {/* <NavBarItem image_src={profile_white} image_alt={sideBarNames.profile} name={sideBarNames.profile} route='profile'/> */}
                    
                        {/* <div className={classes.navbar_group_btns}> */}
                            {/* <a href="/profile">
                            <div className={classes.logout}>
                                    <IconContainerMedium color='white' image_src={profile_white} alt='profile'/>
                                </div> 
                            </a> */}
                            
                            {/* <div className={classes.language_container} onClick={toggleLanguageHandler}>
                                <IconContainerMedium color='white' image_src={translate} alt='translate'/>
                            </div> */}
                            {/* <div className={classes.logout} onClick={logoutHandler}>
                                <IconContainerMedium color='white' image_src={sign_out_white} alt='logout'/>
                            </div>  */}
                        {/* </div> */}
                        <span className={classes.navbar_footer_text_desktop} style={{display:"contents"}}>
                            Powered by
                            <a  style={{color: "#fff", fontWeight: "bold"}} rel="noreferrer" href="https://abracadabra.net/" target='_blank'>
                                <img src="/abracadabra.png" style={{width:"1.5em", marginRight:"0.5em"}} alt="abracadabra.net"/>
                                Abracadabra.net
                            </a>
                        </span>

                        {/* <div className={classes.logo_container}>
                        {props.showSideBar && <div className={classes.logo}>
                            <div className={classes.logo_text}><img src={foreshop} alt='ForeMedia' style={{paddingTop:"0.4em"}}/></div>
                        </div>}
                        {!props.showSideBar && <div className={classes.logo}>
                            <div className={classes.logo_text}><img src={foreshopSmall} alt='ForeMedia' style={{paddingTop:"0.4em", width:"45px"}}/></div>
                        </div>}
                    </div> */}
                    </div>
                </div>
            </div>
            )}
        </>
    )
}

export default NavBar;